@import 'style/_variables.scss';
@import 'style/_mixins.scss';

.grecaptcha-badge {
  visibility: hidden;
}

.contact {
  padding: 0 20px;
  margin: 40px 0 20px;
  max-width: 1280px;
  width: 100%;
  display: grid;
  grid-template-columns: 250px 1fr 110px;
  gap: 10px 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h3 {
    color: $white;
    text-align: left;
    align-self: start;
    margin-top: 6px;
    span {
      display: inline-block;
      background: $black;
      line-height: 0;
      &::before {
        content: "\A";
        white-space: pre;
      }
    }
  }
}

.recaptcha-disclaimer {
  color: $secondary;
  font-size: 12px;
  color: rgb(61, 61, 61);
  margin: 0;
  grid-column: span 2;
  .footer-logo-container {
    color: rgb(124, 124, 124) !important;
    &:hover {
      color: $secondary-pop;
    }
  }
}

.subscribe-message {
  text-align: center;
  font-size: .825em;
  margin-top: 50px;
  position: absolute;
  &.sending{
    color: $secondary-pop;
  }
  &.error{
    color: red;
  }
  &.success{
    color: $pop;
  }
}


.contact-form {
  width: 100%;
  grid-column: span 2;
  flex: 1;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid white;
  margin-bottom: 1.5em;
  label {
    color: white;
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: 4px;
    padding-left: .4em;
  }
  input {
    height: 40px;
    background: none;
    color: $white;
    border: none;
    &[type="email"]{
      width: 100%;
      height: 40px;
      color: $white;

      @include smoothness(.2s);
    }
  }
  .submit-button {
    background: none;
    height: 40px;
    margin-bottom: 0;
    padding: 0;
    border: none;
    text-transform: uppercase;
    letter-spacing: .1em;
    font-weight: 300;
    border-radius: 0 8px 8px 0;
    width: 154px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
    .subscribe-arrow {
      display: inline;
      height: 15px;
    }
    &:hover {
      transform: scale(1.05);
      cursor: pointer;
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active,
input:-internal-autofill-selected  {
    box-shadow: 0 0 0 50px $black inset; 
    -webkit-box-shadow: 0 0 0 50px $black inset; 
    -webkit-text-fill-color: white;
}

.copyright{
  grid-row: 3;
  grid-column: span 3;
  // justify-self: center;
  font-size: 12px;
  opacity: .4;
}

@media only screen and (max-width: 690px) {
  .contact {
    display: grid;
    justify-content: left;
    align-items: flex-start;
    grid-template-rows: repeat(4, auto);
    grid-template-columns: 1fr auto;
    gap: 20px;
    .footer-logo-container {
      grid-column: 2;
      grid-row: 4;
      align-self: end;
    }
    h3 {
      grid-row: 1;
      grid-column: span 2;
    }
  }
  
  .subscribe-message {
    margin-top: -12px;
  }
  .contact-form {
    margin-bottom: 0;
    grid-row: 2;
    grid-column: span 2;
  }

  .recaptcha-disclaimer {
    grid-row: 3;
    grid-column: span 2; 
  }

  .copyright {
    grid-column: 1;
    grid-row: 4;
    align-self: center;
    justify-self: left;
  }
  .full-height .copyright {
   bottom: 36px; 
  }
}