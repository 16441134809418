/*
 * Typography
 *
 * Website-wide text styling
 *
 */

@import 'variables.scss';
//nunito-sans
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500&display=swap');

@font-face {
  font-family: "United Sans";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/UnitedSansRgMd.otf") format("opentype"),
}
@font-face {
  font-family: "United Sans";
  font-style: normal;
  font-weight: 900;
  src: url("../fonts/UnitedSansRgHvy.otf") format("opentype"),
}


* {
	font-size: inherit;
	font-weight: inherit;
  color: inherit;
  vertical-align: inherit;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'United Sans', sans-serif;
  font-weight: 400;
}

a {
	color: inherit;
	text-decoration: none;
}

strong {
  font-weight: 700;
}

h1,  h2,  h3,  h4,  h5,  h6 {
	margin: 0px;
}

p + p {
	margin-top: .75em;
}

li + li {
  margin-top: .5em;
}

h1 {
  font-family: 'United Sans', sans-serif;
  font-size: 2.5rem;
  font-weight: 300;
  line-height: 1.25em;
}

h2 {
  font-family: 'United Sans', sans-serif;
  font-size: 1.25rem;
  font-weight: 400;
  margin-bottom: 10px;
}

h3 {
  font-family: 'United Sans', sans-serif;
  font-size: 1.125rem;
  font-weight: 400;
  margin-bottom: 10px;
  line-height: 1.2em;
}

h4 {
  font-family: 'United Sans', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: .25em;
}

p {
  font-family: 'United Sans', sans-serif;
  font-size: 1.125rem;
  font-weight: 400;
}

@media only screen and (max-width: 540px) {
  h1 {
    font-size: 10vw;
  }
}