 /*
 * App Style
 *
 * The base style for the application.
 *
 */
 @charset "utf-8/index";

 @import '_base.scss';
 @import '_typography.scss';
 @import '_variables.scss';
 @import '_mixins.scss';


@-webkit-keyframes fontfix {
  from { opacity: 1; }
  to { opacity: 1; }
}

//selection-based styling
::selection {
  background: $pop; /* WebKit/Blink Browsers */
}

html {
  --top-spacing: 100px;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $black;
}

a {
  text-decoration: none;
  transition: .4s cubic-bezier(.19,1,.22,1);
  color: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button {
  background: $secondary-pop;
  height: 60px;
  font: inherit;
  cursor: inherit;
  padding: 0 40px;
  border: none;
  color: white;
  transition: .3s ease-in-out;
  border-radius: 8px;
  &:hover {
    background: $pop;
    transform: translateY(-1px) scale(1.05);
  }
  &:active {
    background: $pop;
    transform: none;
  }
}

.marquee {
  font-family: 'Mukta', sans-serif;
  font-size: 6rem;
}
.colored-section {
  color: white;
}

a.varjo-link {
  color: $secondary-pop;
}

.page-title {
  margin-top: 50px;
  text-align: left;
  font-weight: 700;
}

.page-content {
  max-width: 800px;
  margin: 0 auto;
}

.CookieConsent {
  align-items: baseline;
  background: $secondary-shade;
  color: white;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  left: 0px;
  position: fixed;
  width: 100%;
  z-index: 999;
  bottom: 0px;
  padding: 10px 20px;
  gap: 10px;
  button {
    background-color: $pop;
    color: $white;
    padding: 0.625em 2em;
    height: 40px;
    cursor: pointer;
    &.cookie-consent-decline {
      background-color: transparent;
      border: 2px solid $pop;
      color: $pop;
    }
  }
}

.cookie-link {
  text-decoration: underline;
  &:hover {
    color: $pop;
    @include smoothness(.2s);
  }
}

.cookie-consent-buttons {
  display: flex;
  gap: 10px;
}

@media only screen and (max-width: 840px) {
  .page-content {
    margin: 0 20px;
  }
}

@media only screen and (max-width: 920px) {
  .CookieConsent {
    padding: 20px;
  }
}