 @import 'style/_variables.scss';
 @import 'style/_mixins.scss';


.inquire {
  width: 100%;
  .inquire-container {
    display: flex; 
    flex-direction: column; 
    justify-content: center;
    align-items: center;
    position: relative;
  }
  iframe {
    width: 100%;
    height: 100vh;
    // filter: invert(1);
    padding-top: 100px;
  }
}