/*
 *
 * Base element styling and resets
 *
 */

 html {
   box-sizing: border-box;
   overscroll-behavior-y: none;
 }

 body {
 	overflow: hidden;
 	margin: 0px;
 	padding: 0px;
  border-style: none;
  overscroll-behavior-y: none;
 }

 html, body {
  position:relative;
  overflow-x: hidden;
}

 #root {
   height: auto;
 }

 /* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: none; 
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #EEEEEE55; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #E37625; 
}

*,
*:before,
*:after {
	box-sizing: border-box;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: transparent;
  outline: none;
}

ul {
	padding: 0px;
  margin: 0px;
}

li {
  margin-left: 0px;
}

cite {
	display: inline-block;
	font-style: normal;
}

fieldset {
  padding: 0;
  border: 0;
  margin: 0;
}

legend {
  padding: 0;
}
