@import 'style/_variables.scss';
@import 'style/_mixins.scss';

.header {
  position: fixed;
  top: 0;
  opacity: 0;
  z-index: 5;
  width: 100%;
  padding: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(to bottom, $black, #01060BB7, #01060B00);
  @include smoothness(.3s);
  .header-link {
    color: $white;
    padding: 16px;
    font-size: .825em;
    text-transform: uppercase;
    font-family: $montserrat;
    font-weight: 500;
    letter-spacing: .125em;
    &:hover {
      color: $pop;
    }
    &:active {
      color: $pop;
    }
    span {
      color: $pop;
      padding-right: 4px;
    }
  }
  &.hidden {
    margin-top: -200px;
  }
  .logo {
    grid-row: 2;
    height: 30px;
    @include smoothness(.2s);
  }
}

.header-spacer {
  width: 140px;
}

.header-button {
  color: $white;
  background: $pop;
  border-radius: 8px;
  padding: .625em 2em;
  font-size: .825em;
  font-weight: 500;
  text-transform: uppercase;
  font-family: $montserrat;
  &:hover {
    color: white;
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }
  &:active {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
  }
}

.header-button-secondary {
    background: $secondary-pop;

}

.product-title {
  top: 12px;
  color: $white;
  text-transform: uppercase;
  margin-left: .35em;
  span {
    font-size: .875em;
    -webkit-text-stroke-width: .2px;
    -webkit-text-stroke-color: white;
  }
  @include glow;
}

.desktop-links {
  align-self: right;
  display: inline-block;
  @include smoothness(.2s);
  .header-button {
    margin-left: 1em;
    display: inline-block;
  }
}

.hamburger {
  height: 60px;
  cursor: pointer;
  display: none;
  &svg {
    width: 200px;
    height: 150px;
    cursor: pointer;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  path {
    fill: none;
    -webkit-transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke 0.2s cubic-bezier(0.25, -0.25, 0.75, 1.25);
    -moz-transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke 0.2s cubic-bezier(0.25, -0.25, 0.75, 1.25);
    -o-transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke 0.2s cubic-bezier(0.25, -0.25, 0.75, 1.25);
    -ms-transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke 0.2s cubic-bezier(0.25, -0.25, 0.75, 1.25);
    transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke 0.2s cubic-bezier(0.25, -0.25, 0.75, 1.25);
    stroke-width: 20px;
    stroke-linecap: round;
    stroke: $white;
    stroke-dashoffset: 0px;
  }
  path#top,
  path#bottom {
    stroke-dasharray: 240px 950px;
  }
  path#middle {
    stroke-dasharray: 240px 240px;
  }
  
  &.cross path#top,
  &.cross path#bottom {
    stroke-dashoffset: -650px;
    stroke-dashoffset: -650px;
  }
  &.cross path#middle {
    stroke-dashoffset: -115px;
    stroke-dasharray: 1px 220px;
  }
  &:hover{
    path {
      stroke: $pop;
    }
  }
}

.mobile-nav {
  display: flex;
  background: rgba(0, 0, 0,.25);
  height: auto;
  width: 100%;
  align-items: center;
  flex-direction: column;
  left: -100vw;
  position: absolute;
  top: 0;
  padding-top: 100px;
  padding-bottom: 2em;
  z-index: -1;
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  border-bottom: solid 1px rgba(0,0,0,.1);
  box-shadow: 0 2px 5px 0 rgba(0,0,0,.05);
  @include smoothness(.5s);
  .header-link {
    padding: 20px;
    &:hover {
      color: $secondary-pop;
    }
  }
  .header-button-secondary{
    margin-bottom: 20px;
  }
}

/* slightly transparent fallback for Firefox (not supporting backdrop-filter) */
@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .mobile-nav {
    background: rgba(0 , 0, 0, .8);
  }
}

@media only screen and (max-width: 900px) {
  .mobile-nav.isActive {
    display: flex;
    left: 0;
  }

  .header-button {
    margin-top: 10px;
  }

  .header-button-secondary {
    margin-top: 10px;
  }

  .header {
    padding: 40px 0 40px 20px;
  }

  .desktop-links {
    display: none;
  }
  .hamburger{
    display: inline-block;
  }
}

@media only screen and (max-width: 440px) {
}