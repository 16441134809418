@import 'style/_variables.scss';
@import 'style/_mixins.scss';

.look-inside {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  padding-bottom: 40px;
  img {
    width: 100%;
    max-width: 800px;
    min-width: 0px;
  }
  .skeleton-wrapper {
    z-index: 2;
    overflow: visible;
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    .skeleton-image-wrapper {
      overflow: visible;
      position: absolute;
      width: 74%;
      max-width: 842px;
      height: 100%;
      .skeleton-image {
        position: absolute;
        width: 100%;
        height: 100%;
        margin: 0;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url(/img/galea-skeleton.jpg);
      }
    }
    .skeleton-pin-wrapper {
      position: absolute;
      height: 100%;
      width: 74%;
      max-width: 842px;
      overflow: visible;
      background-clip: content-box, padding-box;
      margin: auto;
      .pin-container-svg {
        background-clip: content-box, padding-box;
        margin: auto;
        width: 100%;
        height: 100%;
        overflow: visible;
        box-shadow: 0 0 0 1000000000px #00000000; 
        -webkit-box-shadow: 0 0 0 1000000000px #00000000; 
        .annotation-connector .connector {
          stroke:$pop;
          stroke-width: 4px;
          stroke-dasharray: 0px 8px;
          stroke-linecap: round;
          animation: dash .5s linear backwards infinite;
        }
      }
    }
    &::after {
      content: '';
      display: block;
      width: 100%;
      padding-bottom: 900px;
    }
  }
  .skeleton-caveat {
    width: 100%;
    max-width: 882px;
    padding: 0 20px;
    color: white;
    text-align: right;
    font-size: 1rem;
  }
}

@media only screen and (max-width: 1140px) {
  .look-inside .skeleton-wrapper {
    padding-right: 0px;
    &::after {
      padding-bottom: calc(75vw + 50px);
    }
  }
}

@media only screen and (max-width: 1000px) {
  .skeleton-wrapper {
    margin: 40px 0;
  }
} 

@keyframes dash {
  to {
    stroke-dashoffset: -8;
  }
}