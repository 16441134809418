@import 'style/_variables.scss';
@import 'style/_mixins.scss';

.callout {
  height: 100%;
  width: 100%;
  justify-content: center;
  gap: 60px;
  margin: 40px 0;
  padding: 2em 40px;
  display: flex;
  flex-direction: row;
  color: $white;
  position: relative;
  @include smoothness(0.2s);
  h2 {
    font-family: $montserrat;
    font-weight: 300;
    font-size: 2em;
    width: 100%;
    max-width: 780px;
    flex: 1;
  }
  h3 {
    font-size: 2em;
    width: 100%;
    max-width: 360px;
    margin-right: 1em;
    &::before {
      width: 60px;
      height: 4px;
      background-color: $secondary-pop;
      content: '';
      position: absolute;
      top: 0;
    }
  }
  .calloutImage {
    width: 100%;
    max-width: 360px;
    object-position: right;
    object-fit: contain;
  }
  a {
    color: $pop;
    cursor: pointer;
    &:hover {
      -webkit-filter: blur(1px) contrast(142%);
      filter: blur(1px) contrast(142%);
      -webkit-transform: scale3d(0.94);
      transform: scale3d(0.94);
    }
  }
}

.callout-title {
  h3 {
    max-width: 1232px;
    width: 100%;
  }
  margin-bottom: 0px;
  margin-top: 140px;
}

@media only screen and (max-width: 1140px) {
  .callout {
     flex-direction: column;
     margin: 1.5em 0;
     gap: 1em;
     h3 {
      font-size: calc(1em + 1.4vw);
     }
     h2 {
      font-size: calc(1em + 1.4vw);
     }
     .calloutImage {
       object-position: left;
       max-width: 300px;
     }
   }

   .callout-title {
    margin-bottom: 0px;
    margin-top: 60px;
   }
 }

 @media only screen and (max-width: 480px) {
  .callout {
    padding: 2em 20px;
    .calloutImage {
      object-position: left;
      max-width: 200px;
    }
  }
}