@import 'style/_variables.scss';
@import 'style/_mixins.scss';

.caseStudies-page {
  padding-top: 114px;
  .page-content-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  p {
    font-family: "Montserrat";
    line-height: 1.5rem;
  }
}

.header {
  opacity: 1;
}

.caseStudies-list-header {
  width: 100%;
  color: white;
}

.caseStudies-list {
padding: 60px 20px;
gap: 20px;
display: flex;
flex-direction: column;
width: 100%;
align-items: center;
max-width: 1280px;
margin: 0 auto;
}

.caseStudy-date {
  margin: 0;
  color: white;
}

.caseStudies-thumb {
  width: 200px;
  height: 100%;
  object-fit: cover;
  
}

.caseStudies-link-text {
  color: $pop;
  @include smoothness(.2s);
  &:hover {
    color: $secondary-pop;
  }
}

.caseStudies-link {
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
}

.caseStudies-blurb {
  color: $white;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  font-family: "montserrat";
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  // -webkit-mask-image: -webkit-gradient(linear, left 95%, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
}

.caseStudy-description {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  width: 100%;
}

.caseStudy-title {
  font-size: 24px;
  color: $white;
  &:hover {
    color: $pop;
    @include smoothness(.2s);
  }
}

@media only screen and (max-width: 700px) {
  .caseStudies-thumb {
    width: 100%;
  }
  .caseStudies-link {
    flex-direction: column;
  }
}