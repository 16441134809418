@import 'style/_variables.scss';
@import 'style/_mixins.scss';

.cta {
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Montserrat, sans-serif;
  .cta-content {
    .cta-button {
      text-transform: uppercase;
      display: block;
      border-radius: 8px;
      background: $pop;
      padding: .625em 2em;
      color: $white;
      &:hover {
        transform: scale(1.05);
      }
    }
  }
}