@import 'style/_variables.scss';
@import 'style/_mixins.scss';


.specs {
  font-family: $montserrat;
  flex-direction: column;
  padding-bottom: 160px;
  .specs-table-container {
    display: flex;
    padding: 0 40px 60px;
    flex-direction: row;
    justify-content: center;
    gap: 40px;
    .specs-table {
      display: table;
      gap: 1.5em;
      grid-template-columns: 120px 1fr 120px 1fr;
      max-width: 600px;
      color: $white;
      thead, td {
        text-align: left;
        padding-bottom: 1em;
      }
      thead {
        font-size: 1.5rem;
        font-family: 'United Sans', 'sans-serif';
        font-weight: 400;
        display: block;

      }
    }
  }
}

.spec-list {
  font-size: 1.25em;
  font-weight: 300;
  span {
    color: $pop;
  }
  a {
    color: $secondary-pop;
    cursor: pointer;
    &:hover {
      -webkit-filter: blur(1px) contrast(142%);
      filter: blur(1px) contrast(142%);
      -webkit-transform: scale3d(0.94);
      transform: scale3d(0.94);
    }
  }
  li + li {
    margin-top: .25em;
  }
  li li {
    margin-left: 2em;
    &:first-of-type {
      margin-top: .4rem;
    }
  }
}

.spec-label {
  color: $pop;
  font-family: 'United Sans', sans-serif;
  font-weight: 400;
  padding-right: 10px;
}

@media only screen and (max-width: 900px) {
  .specs .specs-table-container {
    flex-direction: column;
    .spec-list {font-size: calc(1em + 1vw);}
  }
}

@media only screen and (max-width: 600px) {
  .specs .specs-table-container {
    .specs-table {
      grid-template-columns: 1fr;
      gap: 1em 2em;
      .spec-list:first-of-type {
        padding-bottom: 2em;
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .specs .specs-table-container {
    padding: 0px 20px 60px;
    .specs-table {
      grid-template-columns: 1fr;
      gap: 1em 2em;
      .spec-list:first-of-type {
        padding-bottom: 2em;
      }
    }
  }
}