@import 'style/_variables.scss';
@import 'style/_mixins.scss';


.features {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 60px;
  img {
    width: 100%;
    max-width: 760px;
    min-width: 0px;
  }
}

.galea-exploded {
  filter: drop-shadow(2px 4px 4px #37c4f375);
	margin-bottom: 60px;
  max-width: 1400px;
	outline: 1px solid transparent;
	box-shadow: 0 0 1px rgba(0, 0, 0, 0.05);
	z-index: -1;
  // SVG styling
	.st0{fill:#37C5F3;}
	.st1{opacity:0.95;fill:#0B0F14;enable-background:new    ;}
	.st2{fill:#0B0F14;}
	.st3{fill:url(#SVGID_1_);}
	.st4{fill:#A44F24;}
	.st5{fill:url(#SVGID_2_);}
	.st6{clip-path:url(#SVGID_4_);}
	.st7{fill:url(#SVGID_5_);}
	.st8{fill:url(#SVGID_6_);}
	.st9{fill:url(#SVGID_7_);}
	.st10{fill:url(#SVGID_8_);}
	.st11{fill:url(#SVGID_9_);}
	.st12{fill:url(#SVGID_10_);}
	.st13{fill:url(#SVGID_11_);}
	.st14{fill:#FFC33E;}
	.st15{fill:url(#SVGID_12_);}
	.st16{fill:url(#SVGID_13_);}
	.st17{fill:url(#SVGID_14_);}
}

.patent-pending {
	color: white; 
	margin-bottom: 60px;
}

@media only screen and (max-width: 500px) {
  .galea-exploded {
		margin-bottom: 40px;
	}

	.patent-pending {
		color: white; 
		margin-bottom: 0px;
	}
}