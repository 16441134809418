@import 'style/_variables.scss';
@import 'style/_mixins.scss';

.announcement-container {
  background: linear-gradient(to right, rgba(0, 163, 221, 0), rgba(0, 163, 221, 1) 30%, rgba(0, 163, 221, 1) 70%, rgba(0, 163, 221, 0));
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  position: relative;
  padding: 16px;
  color: $white;
  font-weight: bold;
  @include smoothness(.2s);
  &:hover {
    cursor: pointer;
  }
}

@media only screen and (max-width: 900px) {
  .tracker-container::before {
    content: unset;
  }
  .tracker {
    flex-direction: column;
    flex: unset;
    width: unset;
    padding-bottom: 60px;
  }
  .tracker-section {
    display: flex;
    flex-direction: row;
    gap: 20px;
    .tracker-block {
      transform: unset;
      width: 180px;
    }
  }
  .tracker-section.tracker-section-open {
    .tracker-block {
      width: 220px;
      margin-top: -2px;
      margin-bottom: -5px;
      margin-right: 0px;
    }
  }
  .tracker-date {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .ship-date, .ship-date-label {
      margin: 0;
    }
  }
}

@media only screen and (max-width: 480px) {

  .tracker {
    padding: 0 20px;
  }
}