@import 'style/_variables.scss';
@import 'style/_mixins.scss';

.header {
  opacity: 1;
}

.blog-page {
  .rule-grey {
    height: 1px;
    color: grey;
  }
  .overhang-primary-content {
    padding-bottom: 80px;
    margin-bottom: 40px;
  }
}