@import 'style/_variables.scss';
@import 'style/_mixins.scss';

.photo-overlay-container {
  position: fixed;
  z-index: 99;
  top: 0;
  height: 100vh;
  width: 100vw;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}

.photo-overlay {
  position: relative;
  pointer-events: all;
  height: auto;
  max-height: 100vh;
  width: auto;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // gap: 40px;
  button {
    background: none;
    border: none;
  }
  .close-button {
  position: absolute;
  top: 10px;
  height: 40px;
  margin-right: 20px;
  padding: 8px;
  right: 0;
  cursor: pointer;
  fill: $pop;
  background: $white;
  border-radius: 5000px;
  svg {
    transform: scale(.8);
  }
  &:hover {
    background: $pop;
    fill: white;
    transform: scale(1);
  }
  }
  h4{
    margin-bottom: .5rem;
  }
}

.photo-overlay-image {
  height: auto;
  max-height: 100vh;
  width: 100%;
  object-fit: contain;
  object-position: center;
  // max-width: 600px;
}

.photo-overlay-info {
  max-width: 600px;
  p {
    padding-bottom: 60px;
    letter-spacing: .03em;
  }
}

.overlay-scrim {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba($color: #000000, $alpha: 0.5);
  z-index: 6;
  cursor: pointer;
}

@media only screen and (max-width: 1000px) {
  .photo-overlay {
    flex-direction: column;
  }
  .photo-overlay-image {
  width: 100%;
  max-width: unset;
  }
}

@media only screen and (max-width: 480px) {
  .photo-overlay .close-button {
    margin-right: 10px;
  }
  .photo-overlay-container {
    padding: unset;
  }
}