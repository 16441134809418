@import 'style/_variables.scss';
@import 'style/_mixins.scss';

.tracker-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  position: relative;
  padding-bottom: 120px;
  &::before {
    content: "";
    position: absolute;
    top: 130px;
    height: 2px;
    width: 100%;
    background: linear-gradient(to right, rgba(0, 163, 221, 0), rgba(0, 163, 221, 1) 10%, rgba(0, 163, 221, 1) 90%, rgba(0, 163, 221, 0));
  }
}

.tracker {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  max-width: 1100px;
  width: 100%;
  padding: 80px 40px 0;
  flex: 1;
}

.tracker-block {
  height: 100px;
  transform: skew(-.23rad);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: black;
  border: double 3px transparent; //this is the magic sauce that makes the radius gradient border work
  background-origin: border-box;
  background-clip: content-box, border-box;
  background-image: linear-gradient(black, black), 
                      linear-gradient(to bottom, #104160, #102337);
  .batch-number {
    font-size: 2em;
    margin-bottom: 0;
  }
  .batch-status {
    margin-top: 0;
    font-size: 1.5em;
  }
}

.tracker-date {
  p{
    font-weight: 900;
    text-align: center;
  }
}

.tracker-section {
  flex: 1;
  &:first-child .tracker-block {
    border-radius: 20px 0 0 0;
  }
  
}
.ship-date-label {
  color: #9aa0a5;
  margin-bottom: .125em;
  font-size: .95em;
}

.ship-date {
  font-size: 1.4em;
  margin-top: .125em;
}

.tracker-section:first-of-type .tracker-block {
  border-radius: 20px 0 0 0;
}

.tracker-section.tracker-section-reserved {
    color: #9aa0a5;
    .tracker-block {
      background-image: linear-gradient(black, black), 
                        linear-gradient(to bottom, #919aa1, #555d63);
      .batch-status {
        font-size: 1.6em;
      }
    }
}

.tracker-section.tracker-section-open {
  z-index: 3;
  .tracker-block {
    border-radius: 20px 0 0 0;
    background-image: linear-gradient(black, black), 
                      linear-gradient(to bottom, #ffbd41, #b35f21);
    padding-top: 0px;
    height: 120px;
    margin-left: 2px;
    margin-right: -2px;
    .batch-status {
      font-size: 3.2em;
      margin-bottom: .5em;
    }
  }
  .ship-date-label {
    color: white;
  }
  .ship-date, .tracker-block {
    color: $pop;
  }
}

.tracker-section.tracker-section-open + .tracker-section-upcoming {
  .tracker-block {
    background-image: linear-gradient(black, black), 
                      linear-gradient(to bottom, #1cffff, #1483ab);
    & p {
      color: white;
    }
  }
  & + .tracker-section-upcoming {
    .tracker-block {
      background-image: linear-gradient(black, black), 
                        linear-gradient(to bottom, #0064a5, #003560);
    }
  }
}

.tracker-section.tracker-section-upcoming {
  .tracker-block p {
    color: #9aa0a5;
  }
  .ship-date, .tracker-block {
    color: white;
  }
}

@media only screen and (max-width: 900px) {
  .tracker-container::before {
    content: unset;
  }
  .tracker {
    flex-direction: column;
    flex: unset;
    width: unset;
    padding-bottom: 60px;
  }
  .tracker-section {
    display: flex;
    flex-direction: row;
    gap: 20px;
    .tracker-block {
      transform: unset;
      width: 180px;
    }
  }
  .tracker-section.tracker-section-open {
    .tracker-block {
      width: 220px;
      margin-top: -2px;
      margin-bottom: -5px;
      margin-right: 0px;
    }
  }
  .tracker-date {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .ship-date, .ship-date-label {
      margin: 0;
    }
  }
}

@media only screen and (max-width: 480px) {

  .tracker {
    padding: 0 20px;
  }
}