 @import 'style/_variables.scss';
 @import 'style/_mixins.scss';

.loader-container {
  height: 100vh;
}

body.no-scroll {
  overflow: hidden;
}

.splash {
position: absolute;
background: $black;
top: 0;
width: 100%;
height: 100vh;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
z-index: 5;
  circle, ellipse, path {
    fill: currentColor;
  }
  .outer-group{
    color:#9F531A;
    opacity: 1;
    transform-origin: '50% 50%';
  }
	.inner-group{color:#E37625; opacity: 1;}
	.center-dot{color:#FF9444; opacity: 1;}
}

.splash-logo {
  fill: white;
  flex: 1;
  align-self: center;
  height: 60px;
  width: 60px;
}

@media only screen and (max-width: 920px) {
  .trusted-by .partner-logos {
    grid-gap:  4vw;
  }
}

@media only screen and (max-width: 768px) {
  .hero .hero-content {
    margin-left: 20px;
    @include smoothness(.2s);
  }
}