@import 'style/_variables.scss';
@import 'style/_mixins.scss';

@keyframes GLOW {
  0% {
    -webkit-filter: drop-shadow(0 0 5px $secondary);
    filter: drop-shadow(0 0 5px $secondary);
  }

  50% {
    -webkit-filter: drop-shadow(0 0 13px $secondary);
    filter: drop-shadow(0 0 13px $secondary);
  }

  100% {
    -webkit-filter: drop-shadow(0 0 5px $secondary);
    filter: drop-shadow(0 0 5px $secondary);
  }
}

.hero {
  max-height: 100vh;
  width: 100%;
  margin: 0 0 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
  // iOS hotfix
  min-height: -webkit-fill-available;
  // Hide scrollbar FOIC
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  overscroll-behavior-y: none;

  &.full-height {
    height: 100vh;
    min-height: 780px;
  }

  h1 {
    color: white;
    max-width: 840px;
    padding: 0 20px;
    text-align: center;
  }
}

.hero::-webkit-scrollbar {
  display: none;
}

.hero-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  object-fit: contain;
  min-width: 0;
  min-height: 0;
  max-width: 120vh;
  max-height: 100vh;
  height: auto;
  padding: 0 0 0 40px;

  .cta {
    display: none;
  }
}

.hero-img-container {
  display: flex;
  max-height: 100%;
  max-width: 110%;
}

.static-hero-image {
  display: flex;
  max-height: 60%;
  max-width: 80%;
  margin-bottom: 40px;
  padding: 3%;
  animation-name: GLOW;
  animation-duration: 6s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

.hero-img {
  pointer-events: none;
  object-fit: contain;
  justify-self: center;
  align-self: center;
  min-width: 0;
  flex: 1;
  width: 100%;
  height: auto;
}

.hero-video-container {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  overflow: hidden;
  z-index: -13;

  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100px;
    content: '';
    background: linear-gradient(to bottom, #01060B00, #01060B);
  }
}

.hero-video {
  width: 100%;
  height: 100%;
  opacity: .6;
  object-fit: cover;
  bottom: 0;
  overflow: hidden;

  video {
    height: 100%;
    width: 177.77777778vh;
    /* 100 * 16 / 9 */
    min-width: 100%;
    min-height: 56.25vw;
    /* 100 * 9 / 16 */
    position: absolute;
    left: 50%;
    /* % of surrounding element */
    top: 50%;
    transform: translate(-50%, -50%);
    /* % of current element */
  }
}


@media only screen and (max-width: 900px) {
  .hero.full-height {
    min-height: unset;
  }

  .hero.full-height .hero-container {
    height: 100%;
  }

  .hero-container {
    grid-template-columns: 1fr;
    grid-template-rows: 60px 1fr;
    padding-left: 0;
    padding: 20px 0 0 0;

    .cta {
      display: flex;
    }
  }

  .hero-img-container {
    grid-column: 1;
    display: flex;
  }

  .logo {
    grid-row: 1;
    justify-self: center;
    height: calc(30px + 2vw);
  }
}

@media only screen and (max-height: 860px) {
  .hero-img {
    height: 100%;
  }

  .hero-container {
    max-width: 900px;
  }
}

@media only screen and (max-width: 690px) {

  .hero-img-container {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    max-width: 100%;

    .logo {
      position: relative;
      margin: 0;
      width: 160px;
    }

    .hero-img {
      margin: 0;
    }
  }
}


@media only screen and (max-width: 550px) {
  .hero-img-container {
    padding: 0 0 40px 10px;
  }

  .hero h1 {
    font-size: 7vw;
  }
}