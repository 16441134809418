/*
 * VARIABLES
 *
 * Global sizes and colors that can be used in every component
 *
 */

$united-sans: "United Sans", "system-ui",sans-serif;
$montserrat: "Montserrat", "system-ui", sans-serif;


/* ============================================================ *\
|* Colors
\* ============================================================ */

$white:           #FFF;
$grey-light:      #E6E7E8;
$pop:             #E37625;
$secondary-pop:   #00A3DD;
$secondary-light: #9AADCE;
$secondary:       #436FA0;
$secondary-shade: #0E2542;
$secondary-dark:  #030A12;
$black:           #01060B;