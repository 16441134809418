@import 'style/_variables.scss';
@import 'style/_mixins.scss';

.footer-wiggle {
  width: 100%;
  margin-top: -3.4375vw;
  margin-bottom: -5px;
  @include noSelect;
}

.footer {
  height: auto;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: white;
  border-top: solid 2px $secondary-pop;
  position: relative;
  .footer-logo {
    height: 40px;
    width: 106px;
    opacity: 0.2;
    margin-right: 2px;
    @include smoothness(.2s);
    img {
      object-fit: scale-down;
    }
    &:hover {
      cursor: pointer;
      opacity: .5;
    }
  }
  a {
    color: white;
    @include smoothness(.2s);
    &:hover {
      color: $pop;
    }
  }
  svg {
    fill: white;
    height: 30px;
    width: 30px;
    margin-right: 1em;
    @include smoothness(.2s);
    &:hover {
      fill: $pop;
    }
  }
} 

.footer-video-container {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  z-index: -13;
  margin-top: -900px;
}

.footer-video {
  width: 100%;
  height: 100%;
  opacity: .6;
  object-fit: cover;
  bottom: 0;
  overflow: hidden;
  video {
    height: 100%;
    width: 177.77777778vh; /* 100 * 16 / 9 */
    min-width: 100%;
    min-height: 56.25vw; /* 100 * 9 / 16 */
    position: absolute;
    left: 50%; /* % of surrounding element */
    transform: translate(-50%, -100%); /* % of current element */
  }
}

@media only screen and (max-width: 840px) {
  .footer-section-main {
    grid-column: span 3;
    align-items: center;
  }
  .footer-top{
    height: 400px;
    grid-template-columns: repeat(3, auto);
    grid-template-rows: repeat(2, auto);
  }
  .footer-bottom {
    p:nth-child(2){
      position: absolute;
      left: auto;
      right: 20px;
    }
  }
}

@media only screen and (max-width: 540px) {
  .footer-section-main {
    grid-column: 1;
  }
  .footer-section {
    margin-left: 0;
  }
  .footer-label {
    margin-top: 20px;
  }
  .footer-top{
    height: 800px;
    text-align: center;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, auto);
  }
  .footer-bottom {
    display: flex;
    height: auto;
    flex-direction: column-reverse;
    p {
      position: relative !important;
      flex: 1;
      left: auto !important;
      right: auto !important;
    }
  }
  ol {
    padding-inline-start: 0;
  }
}