@import 'style/_variables.scss';
@import 'style/_mixins.scss';

.newsletter {
  width: 100%;
  padding-top: 114px;
  color: white;
  a {
    color: $secondary-pop;
  }
  h3 {
    text-transform: unset;
  }
  .overhang-primary-content {
    padding-bottom: 0;
  }
  .button {
    width: 100%;
    max-width: 200px;
  }
  .subscribe-status-message {
    margin-top: 20px;
  }

  .previous-campaigns-link {
    padding: 5px 0 30px;
  }
  .button {
    margin-top: 40px;
    font-family: 'Montserrat', sans-serif;
    border-radius: 8px;
    background: #E37625;
    padding: 0.625em 2em;
    height: unset;
  }
}

.newsletter-form-wrapper {
  width: 100%;
  max-width: 1320px;
  padding: 0 300px 100px 60px;
  @include smoothness(.2s);
}

.newsletter-primary-inputs {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px 40px;
  padding: 40px 0;
  input {
    background: none;
  }
  select {
    background: url("data:image/svg+xml;base64,PHN2ZyAKIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIgogd2lkdGg9IjIzcHgiIGhlaWdodD0iMTRweCI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgIGZpbGw9InJnYigyMjcsIDExOCwgMzcpIgogZD0iTTIyLjE4OCwyLjkwNyBMMTEuMzk4LDEzLjgzNSBMMTAuOTE3LDEzLjM1NCBMMTAuNzg3LDEzLjQ4MyBMMC4zMDksMi44ODQgTDIuMzAwLDAuODkzIEwxMS4yNjgsOS45NjMgTDIwLjE5NywwLjkxNSBMMjIuMTg4LDIuOTA3IFoiLz4KPC9zdmc+");
    background-repeat: no-repeat;
    background-position: center right;
    background-size: 18px;
    -moz-appearance: none; 
    -webkit-appearance: none; 
    appearance: none;
    &:hover {
      cursor: pointer;
    }
  }
  input, select {
    border: none;
    border-radius: unset;
    border-bottom: 1px solid $white;
    height: 40px;
    width: 100%;
    flex: 1;
    padding-bottom: 6px;
    font-family: 'Montserrat', sans-serif;
    &:hover, &:focus {
      border-bottom: 1px solid $pop;
    }
  }
}


.permissions-checkboxes {
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 40px 0;
}
.checkbox-container {
  display: flex;
  flex-direction: row;
  gap: 1em;
  label {
    margin: 0;
    color: $secondary-dark;
    font-weight: 500;
  }
}

@media only screen and (max-width: 1200px) {
  .newsletter{
    .overhang-primary-content {
      padding-right: 420px;
    }
    .overhang-support-sidenote {
      display: none;
    }
  }
}

@media only screen and (max-width: 1000px) {
  .newsletter{
    .overhang-primary-content {
      padding-right: 120px;
    }
    .newsletter-form-wrapper {
      padding-left: 40px;
      padding-right: 120px;
    }
  }
}

@media only screen and (max-width: 700px) {
  .newsletter{
    .overhang-primary-content {
      padding-right: 0;
    }
    .newsletter-form-wrapper {
      padding-right: 40px;
    }
    .newsletter-primary-inputs {
      grid-template-columns: 1fr;
    }
  }
}

@media only screen and (max-width: 480px) {
  .newsletter{
    .overhang-primary-content {
      padding-right: 20px;
    }
    .newsletter-form-wrapper {
      padding: 0 20px 100px;
    }
    .newsletter-primary-inputs {
      grid-template-columns: 1fr;
    }
  }
  .button {
    max-width: unset;
  }
}