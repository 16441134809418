@import 'style/_variables.scss';
@import 'style/_mixins.scss';
@import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.6.3/css/font-awesome.min.css');

.carousel-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-bottom: 160px;
  width: 100%;
}

.carousel {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 100%;
  .slick-prev, .slick-next {
    width: 80px;
    height: 100%;
    left: unset;
    z-index: 99;
    position: absolute;
    z-index: 5;
    &:hover {
      transform: translate(0, -50%);
    }
    &::before{
      content: '';
    }
  }
  .slick-next {
    right: 0;
  }
}

.slick-slider {
  .slick-prev, .slick-next {
    background-image: url("../../../img/arrow-white.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 50%;
    transform: none;
    &:hover {
      background-image: url("../../../img/arrow-orange.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 50%;
    }
    &:active, &:focus {        
      background-image: url("../../../img/arrow-white.png");
      background-repeat: no-repeat;
      background-size: 50%;
    }
  }
}

.slick-slider {
  // .slick-arrow {
  //   height: 40px;
  //   width: 40px;
  // }
  .slick-next {
    transform: translate(0, -50%);
  }
  .slick-prev {
    transform: rotate(180deg);
    transform-origin: center 25%;
    &:hover, &:active {
      transform: rotate(180deg);
    }
  }
  .slick-next, .slick-prev {
    z-index: 3;
    background-position: center !important;
  }
  .slick-prev:before, .slick-next:before {
    display: none;
  }
}

.slick-track
{
    display: flex !important;
    .slick-slide
    {
        height: auto;
        margin: 0 .325em;
        overflow: hidden;
    }
}


.carousel-slide {
  width: auto;
  height: auto;
  position: relative;
}

.carousel-slide {
  cursor: pointer;
  height: 100%;
}

.carousel-slide img {
  display: inline-block;
  width: 100%;
  height: inherit;
  object-fit: cover;
}

.carousel-slide-video {
  &:before {
    position:absolute;
    z-index: 99;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    content: "\f01d";
    font-family: FontAwesome;
    font-size: 100px;
    color: #fff;
    opacity: .8;
    text-shadow: 0px 0px 30px rgba(0, 0, 0, 0.5);
  }
  &:hover:before {
    color: #eee;
  }
}

.slick-slide[aria-hidden="false"] {

  display: flex;
}

.slick-list {
  position: relative;

}

.slick-active {
  position: relative;
  img {
    transition: transform 1s ease-in-out;
  }
  &:hover{
    img {
      transform: scale(1.2);
    }
  }
}

.slick-slide[aria-hidden="true"] {
  opacity: .5;
  transition: opacity 1s;
  &:hover {
    opacity: 1;
  }
}

.slick-track {
  height: 100%;
}

.slick-slide>div {
  height: 100%;
  &>iframe {
    height: 100%;
  }
}

@media only screen and (max-width: 800px) {
  .slick-slider .slick-next, .slick-slider .slick-prev {
    background: none !important;
  }
}